import React, { useRef, useEffect, useState } from "react";
import axios from "axios";
import dateformat from "dateformat";
import { useReactToPrint } from "react-to-print";
import noDataImg from "../../../images/cloud.png";
import "./data.scss";
import { useGlobalContext } from "../../../context";
import LineChart from "../../../common/LineChart";
import Spinner from "../../Spinner";
import { ExportToExcel } from "../../ExportToExcel";
import { DownloadTableExcel } from "react-export-table-to-excel";

function reverseArr(input) {
  var ret = new Array();
  for (var i = input.length - 1; i >= 0; i--) {
    ret.push(input[i]);
  }
  return ret;
}

const getDataSet = (arr) => {
  const colorArr = [
    "#FF0000",
    "#00FF00",
    "#0000FF",
    "#FF00FF",
    "#FFFF00",
    "#00FFFF",
    "#FFA500",
    "#800080",
    "#008000",
    "#800000",
    "#008080",
    "#FF4500",
    "#9400D3",
    "#00FF7F",
    "#FF1493",
    "#00BFFF",
    "#8A2BE2",
    "#32CD32",
    "#DC143C",
    "#FF69B4",
  ];
  let hold = {};
  let result = [];
  for (let i = 0; i < arr.length; i++) {
    const data = arr[i];
    for (const key in data) {
      if (!hold[key]) {
        hold[key] = true;
      } else if (key !== "createdAt") {
      }
      hold[key] = true;
    }
  }

  for (const key in hold) {
    if (key !== "createdAt") {
      result.push({
        label: key,
        data: arr.map((data) => data[key]),
        backgroundColor: ["#ecf0f1"],
        borderColor: colorArr.pop() || "#000000",
        borderWidth: 1,
        pointRadius: 5,
      });
    }
  }
  return result;
};
const initialInterval = {
  min: Date.now() - 1000 * 60 * 60 * 24 * 30,
  max: Date.now(),
};
const intervalReducer = (state, action) => {
  switch (action.type) {
    case "SETALL":
      return { ...state, min: action.payload.min, max: action.payload.max };
    default:
      return state;
  }
};
const Graph = () => {
  const [intervalState, dispatch] = React.useReducer(
    intervalReducer,
    initialInterval
  );
  const [selectedDevice, setSelectedDevice] = useState("");
  const [selectedInterval, setSelectedInterval] = useState(15);
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");
  const [selectedStartTime, setSelectedStartTime] = useState("");
  const [selectedEndTime, setSelectedEndTime] = useState("");
  const [chartData, setChartData] = useState(null);
  const [allData, setAllData] = useState([]);
  const [dataAvg, setDataAvg] = useState(null);

  const chartRef = useRef(null);
  const tableRef = useRef(null);
  const tableContainerRef = useRef(null);
  const [tableView, setTableView] = useState("d-none");
  const [tableHeaderValues, setHeaderValues] = useState([]);
  const [dataMessage, setDataMessage] = useState([]);

  const setIntervalAccMin = (time) => {
    const min = new Date(time).getTime();
    let max = min + 1000 * 60 * 60 * 24 * 30;
    if (max > Date.now()) {
      max = Date.now();
    }
    dispatch({ type: "SETALL", payload: { min, max } });
  };

  const setIntervalAccMax = (time) => {
    const max = new Date(time).getTime();
    let min = max - 1000 * 60 * 60 * 24 * 30;
    dispatch({ type: "SETALL", payload: { min, max } });
  };

  useEffect(() => {
    if (allData.length > 0) {
      setChartData({
        labels: allData.map((data) => data.createdAt),
        datasets: getDataSet(allData),
      });
    }
  }, [allData]);

  const { userType, deviceNameForGraph, selectedIndustriesName } =
    useGlobalContext();

  const today = new Date();
  let todayDate =
    `${today.getDate()}`.length === 1 ? `0${today.getDate()}` : today.getDate();
  let todayMonth =
    `${today.getMonth() + 1}`.length === 1
      ? `0${today.getMonth() + 1}`
      : today.getMonth() + 1;
  let todayYear = today.getFullYear();

  const refSelectedDevice = useRef(null);
  const refSelectedInterval = useRef(selectedInterval);
  const refStartDate = useRef();
  const refEndDate = useRef();
  const refStartTime = useRef();
  const refEndTime = useRef();

  useEffect(() => {
    refStartDate.current.value = `${todayYear}-${todayMonth}-${todayDate}`;
    refEndDate.current.value = `${todayYear}-${todayMonth}-${todayDate}`;
    refStartTime.current.value = `00:00:00`;
    refEndTime.current.value = `${
      String(today.getHours()).length === 1
        ? `0${today.getHours()}`
        : today.getHours()
    }:${
      String(today.getMinutes()).length === 1
        ? `0${today.getMinutes()}`
        : today.getMinutes()
    }:${
      String(today.getSeconds()).length === 1
        ? `0${today.getSeconds()}`
        : today.getSeconds()
    }`;

    setSelectedStartDate(refStartDate.current.value);
    setSelectedEndDate(refEndDate.current.value);
    setSelectedStartTime(refStartTime.current.value);
    setSelectedEndTime(refEndTime.current.value);
  }, []);

  const getData = async (
    device,
    interval,
    startDate,
    endDate,
    startTime,
    endTime
  ) => {
    setDataMessage("Loading...");
    setAllData([]);

    if (device && startDate && endDate && startTime && endTime) {
      // console.log(device)
      try {
        const res = await axios(
          `${window.graphApiURL}/surveykshan-get-data-api/rawdata/${device}/${interval}/${startDate}/${endDate}/${startTime}/${endTime}`
          // `http://localhost:9000/api/v1/surveykshan-get-data-api/rawdata/${device}/${interval}/${startDate}/${endDate}/${startTime}/${endTime}`
        );
        if (res.data.status === "sucess" && res.data.data.length) {
          const tableHeaderArr = Object.keys(res.data.head).filter((item) => {
            if (item !== "createdAt") {
              return item;
            }
          });
          tableHeaderArr.unshift("createdAt");
          setDataMessage("");
          setAllData(res.data.data);
          setHeaderValues(tableHeaderArr);
          setDataAvg(res.data.avgData);
        } else {
          setDataMessage("No data available!");
        }
      } catch (error) {
        setDataMessage(error.message);
      }
    }
  };

  const handlePrint = useReactToPrint({
    content: () => chartRef.current,
  });

  useEffect(() => {
    setSelectedDevice(refSelectedDevice.current.value);
  }, [deviceNameForGraph]);

  useEffect(() => {
    getData(
      selectedDevice,
      selectedInterval,
      selectedStartDate,
      selectedEndDate,
      selectedStartTime,
      selectedEndTime
    );
  }, [
    selectedDevice,
    selectedInterval,
    selectedStartDate,
    selectedEndDate,
    selectedStartTime,
    selectedEndTime,
  ]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSelectedDevice(refSelectedDevice.current.value);
    setSelectedStartDate(refStartDate.current.value);
    setSelectedEndDate(refEndDate.current.value);
    setSelectedStartTime(refStartTime.current.value);
    setSelectedEndTime(refEndTime.current.value);
    // getData(
    //   selectedDevice,
    //   selectedInterval,
    //   selectedStartDate,
    //   selectedEndDate,
    //   selectedStartTime,
    //   selectedEndTime
    // );
  };
  const handleChange = (e) => {
    // if(e.target.value==="last_week"){

    // }
    //  else if (e.target.value === "this_month") {
    //     refStartDate.current.value = `${todayYear}-${todayMonth}-01`;
    //     refEndDate.current.value = `${todayYear}-${todayMonth}-${todayDate}`;
    //     refEndTime.current.value = `${
    //       String(today.getHours()).length === 1
    //         ? `0${today.getHours()}`
    //         : today.getHours()
    //     }:${
    //       String(today.getMinutes()).length === 1
    //         ? `0${today.getMinutes()}`
    //         : today.getMinutes()
    //     }:${
    //       String(today.getSeconds()).length === 1
    //         ? `0${today.getSeconds()}`
    //         : today.getSeconds()
    //     }`;
    //     setIntervalAccMin(refStartDate.current.value);
    //     setIntervalAccMax(refEndDate.current.value);
    //     // setTemp(!temp);
    //   }
    if (e.target.value === "last_month") {
      let lastMonth =
        Number(todayMonth) <= 10
          ? `0${Number(todayMonth) - 1}`
          : `${Number(todayMonth) - 1}`;
      let lastDate;
      if (lastMonth === "00") {
        lastMonth = "12";
        todayYear = `${Number(todayYear) - 1}`;
      }
      if (
        lastMonth === "01" ||
        lastMonth === "03" ||
        lastMonth === "05" ||
        lastMonth === "07" ||
        lastMonth === "08" ||
        lastMonth === "10" ||
        lastMonth === "12"
      ) {
        lastDate = "31";
      } else if (lastMonth === "02") {
        lastDate = "28";
      } else {
        lastDate = "30";
      }
      refStartDate.current.value = `${todayYear}-${lastMonth}-01`;
      refEndDate.current.value = `${todayYear}-${lastMonth}-${lastDate}`;
      refEndTime.current.value = `11:55:00`;
      setIntervalAccMin(refStartDate.current.value);
      setIntervalAccMax(refEndDate.current.value);
      // setTemp(!temp);
    } else {
      // refStartDate.current.value = `${todayYear}-${todayMonth}-${todayDate}`;
      // refEndDate.current.value = `${todayYear}-${todayMonth}-${todayDate}`;
      // refStartTime.current.value = `00:00:00`;
      // refEndTime.current.value = `${
      //   String(today.getHours()).length === 1
      //     ? `0${today.getHours()}`
      //     : today.getHours()
      // }:${
      //   String(today.getMinutes()).length === 1
      //     ? `0${today.getMinutes()}`
      //     : today.getMinutes()
      // }:${
      //   String(today.getSeconds()).length === 1
      //     ? `0${today.getSeconds()}`
      //     : today.getSeconds()
      // }`;
      // setIntervalAccMin(refStartDate.current.value);
      // setIntervalAccMax(refEndDate.current.value);
      // setTemp(!temp);
      window.location.reload();
    }

    // console.log(selectedStartDate,selectedEndDate,selectedStartTime,selectedEndTime);
  };
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="row py-2 align-items-center g-3">
            <div className="col-3">
              <div className="d-flex align-items-center justify-content-end">
                {/* <div className="me-auto" style={{ width: "max-content" }}>
                  <div className="dropdown">
                    <button
                      className="btn btn-secondary dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    ></button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li>
                        <span onClick={handlePrint} className="dropdown-item">
                          print
                        </span>
                      </li>
                      <li>
                        <span
                          onClick={() => exportComponentAsPNG(chartRef)}
                          className="dropdown-item"
                        >
                          Download png
                        </span>
                      </li>
                      <li>
                        <span
                          onClick={() => exportComponentAsJPEG(chartRef)}
                          className="dropdown-item"
                        >
                          Download jpeg
                        </span>
                      </li>

                      <li>
                        <DownloadTableExcel
                          filename="Log-Data"
                          currentTableRef={tableRef.current}
                        >
                          <span className="dropdown-item">Download excel</span>
                        </DownloadTableExcel>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                    </ul>
                  </div>
                </div> */}
                {/* below */}
                {userType === "admin" && (
                  <div className="mx-2" style={{ width: "max-content" }}>
                    <select onChange={handleChange} className="form-select">
                      <option value="">Days</option>
                      <option value="last_month">Last Month</option>
                    </select>
                  </div>
                )}
                {/* above */}
                <div className="mx-2" style={{ width: "max-content" }}>
                  <select
                    onChange={() =>
                      setSelectedDevice(refSelectedDevice.current.value)
                    }
                    className="form-select"
                    ref={refSelectedDevice}
                    defaultValue=""
                  >
                    {deviceNameForGraph ? (
                      <option
                        value={deviceNameForGraph
                          .map(({ device_name }) => device_name)
                          .join("+")}
                      >
                        ALL
                      </option>
                    ) : (
                      <></>
                    )}
                    {deviceNameForGraph ? (
                      deviceNameForGraph.map((item) => {
                        return (
                          <option key={item._id} value={item.device_name}>
                            {item.device_model_number}
                          </option>
                        );
                      })
                    ) : (
                      <option value="">Loading...</option>
                    )}
                  </select>
                </div>
                <div style={{ width: "max-content" }}>
                  <select
                    onChange={() =>
                      setSelectedInterval(refSelectedInterval.current.value)
                    }
                    className="form-select"
                    defaultValue={15}
                    ref={refSelectedInterval}
                  >
                    {userType === "admin" || userType === "serviceengineer" ? (
                      <>
                        <option value={1}>1 min</option>
                        <option value={5}>5 min</option>
                        <option value={15}>15 min</option>
                        <option value={30}>30 min</option>
                        <option value={60}>1 hours</option>
                        <option value={240}>4 hours</option>
                        <option value={480}>8 hours</option>
                        <option value={720}>12 hours</option>
                        <option value={1440}>1 day</option>
                      </>
                    ) : (
                      <>
                        <option value={15}>15 min</option>
                        <option value={30}>30 min</option>
                        <option value={60}>1 hours</option>
                        <option value={240}>4 hours</option>
                        <option value={480}>8 hours</option>
                        <option value={720}>12 hours</option>
                        <option value={1440}>1 day</option>
                      </>
                    )}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-9">
              <form
                onSubmit={handleSubmit}
                style={{ border: "1px solid gray" }}
              >
                <div className="row g-1 p-1 ">
                  <div className="col-10">
                    <div className="row g-1">
                      <div className="col-3">
                        <input
                          ref={refStartDate}
                          className="form-control form-control-sm"
                          type="date"
                          min={
                            userType === "admin"
                              ? ""
                              : dateformat(intervalState.min, "yyyy-mm-dd")
                          }
                          max={
                            userType === "admin"
                              ? ""
                              : dateformat(intervalState.max, "yyyy-mm-dd")
                          }
                          name=""
                          onChange={() =>
                            setIntervalAccMin(refStartDate.current.value)
                          }
                        />
                      </div>
                      <div className="col-3">
                        <input
                          ref={refEndDate}
                          className="form-control form-control-sm"
                          type="date"
                          min={
                            userType === "admin"
                              ? ""
                              : dateformat(intervalState.min, "yyyy-mm-dd")
                          }
                          max={
                            userType === "admin"
                              ? ""
                              : dateformat(intervalState.max, "yyyy-mm-dd")
                          }
                          name=""
                          onChange={() =>
                            setIntervalAccMax(refEndDate.current.value)
                          }
                        />
                      </div>
                      <div className="col-3">
                        <input
                          step={1}
                          ref={refStartTime}
                          className="form-control form-control-sm"
                          type="time"
                          name=""
                        />
                      </div>
                      <div className="col-3">
                        <input
                          step={1}
                          ref={refEndTime}
                          className="form-control form-control-sm"
                          type="time"
                          name=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="row g-1">
                      <div className="col-12">
                        <button
                          type="submit"
                          className="btn btn-sm btn-primary w-100"
                        >
                          <span
                            className="fw-bolder text-white"
                            style={{ fontSize: "12px" }}
                          >
                            Apply
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {chartData && allData.length > 0 ? (
          <>
            <div className="col-12">
              <LineChart ref={chartRef} chartData={chartData} />
            </div>
            <div className="col-12 py-3">
              <div className="d-flex align-item-center justify-content-center">
                {/* <ExportToExcel
                  apiData={reverseArr(allData)}
                  fileName={`${selectedIndustriesName || "Industry"} Data [ ${
                    refStartDate.current.value
                  } ${refStartTime.current.value.split(":").join(".")} to ${
                    refEndDate.current.value
                  } ${refEndTime.current.value.split(":").join(".")} ]`}
                /> */}

                {tableView === "d-block" ? (
                  <DownloadTableExcel
                    filename={`${selectedIndustriesName || "Industry"} Data [ ${
                      refStartDate.current.value
                    } ${refStartTime.current.value.split(":").join(".")} to ${
                      refEndDate.current.value
                    } ${refEndTime.current.value.split(":").join(".")} ]`}
                    currentTableRef={tableRef.current}
                  >
                    <button className="btn btn-sm btn-info mx-3">
                      <span className="text-white">Download excel</span>
                    </button>
                  </DownloadTableExcel>
                ) : (
                  <></>
                )}

                <button
                  className="btn btn-sm btn-primary "
                  onClick={() => {
                    tableView === "d-none"
                      ? setTableView("d-block")
                      : setTableView("d-none");
                  }}
                >
                  {tableView === "d-none" ? "View Table" : "Close Table"}
                </button>
              </div>
            </div>

            {/* <div className="App">
              <table>
                <thead>
                  <tr>
                    {Object.keys(allData[0]).map((item, idx) => (
                      <th key={idx}>{item}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {allData.map((item, idx) => (
                    <tr key={idx}>
                      {Object.keys(allData[0]).map((key, idx) => (
                        <td key={idx}>{item[key]}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div> */}

            <div ref={tableContainerRef} className={`col-12 table__container`}>
              <table
                ref={tableRef}
                style={{ width: "100%" }}
                className={`${tableView}`}
              >
                <thead>
                  <tr>
                    <th
                      colSpan={Object.keys(tableHeaderValues).length}
                      className="py-3 table_head"
                    >
                      <h4 className="text-center">
                        Online Portal For Data Monitoring
                      </h4>
                      <p className="m-0 text-center fw-normal">
                        Industry: {selectedIndustriesName || "---"} <br />
                        {`From Date: ${selectedStartDate || "---"} ${
                          selectedStartTime || "---"
                        } To Date: ${selectedEndDate || "---"}
                        ${selectedEndTime || "---"}`}
                        <br />
                        {`Report: ${
                          selectedInterval < 60
                            ? `${selectedInterval} Mint`
                            : `${selectedInterval / 60} hour`
                        } Avg Report`}
                      </p>
                    </th>
                  </tr>
                  <tr>
                    {tableHeaderValues.map((item, idx) => (
                      <th style={{ width: "100%" }} key={idx}>
                        {item === "createdAt" ? "Date / Time" : item}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {allData.map((item, idx) => (
                    <tr key={idx}>
                      {tableHeaderValues.map((doc, idx) => {
                        const data = item[doc];
                        if (typeof data === "number") {
                          return <td key={idx}>{data.toFixed(2)}</td>;
                        } else {
                          return <td key={idx}>{data}</td>;
                        }
                      })}
                    </tr>
                  ))}
                  {dataAvg ? (
                    <tr>
                      {tableHeaderValues.map((doc, idx) => {
                        if (doc === "createdAt")
                          return (
                            <td className="fw-bold" key={"AVG"}>
                              Average Data
                            </td>
                          );
                        const data = dataAvg[doc];
                        if (typeof data === "number") {
                          return (
                            <td className="fw-bold" key={idx}>
                              {data.toFixed(2)}
                            </td>
                          );
                        } else {
                          return (
                            <td className="fw-bold" key={idx}>
                              {data}
                            </td>
                          );
                        }
                      })}
                    </tr>
                  ) : (
                    <></>
                  )}
                </tbody>
              </table>
            </div>
          </>
        ) : dataMessage === "Loading..." ? (
          <div className="col-12">
            <div className="box_bg rounded-1 py-4 mt-3">
              <div className="p-4">
                <Spinner />
              </div>
            </div>
          </div>
        ) : (
          <div className="box_bg rounded-1 p-4 mt-3">
            <h5 className="text-center py-4">{dataMessage}</h5>
            <div className="d-flex align-items-center justify-content-center">
              <img style={{ width: "200px" }} src={noDataImg} alt="" />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Graph;
