import React from "react";

const CreateNewLicense = () => {
  return (
    <div>
      <h1>CreateNewLicense</h1>
    </div>
  );
};

export default CreateNewLicense;
