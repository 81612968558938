import React, { useEffect } from "react";
import logo from "../../images/logo.png";
import leftImg from "../../images/BG.jpg";
import "./index.css"

const LoginForm = ({
  handleSubmit,
  refPassword,
  refUserName,
  error,
  setError,
  fetchingData,
}) => {
  useEffect(() => {
    const timeId = setTimeout(() => {
      if (error) {
        setError("");
      }
    }, 3000);
    return () => {
      clearTimeout(timeId);
    };
  }, [error]);

  return (
    <div className="d-flex main_container" style={{ minHeight: "100vh", backgroundColor: "white" }}>
      {/* For Left Side Image */}
      <div className="col-7 shadow apply_media_query_left_side" style={{ height: "100%" }}>
        <img src={leftImg} width="100%" style={{ borderTopRightRadius: "12px", borderBottomRightRadius: "12px", height: "38.7rem" }} />
      </div>

      {/* For Right Side Container */}
      <div className="col-5 apply_media_query_right_side">
        <div style={{ minHeight: "100vh", padding: "12px" }} className="apply_media_query_right_side_first">
          <form onSubmit={handleSubmit}>
            <div className="row g-3 m-0 px-2 mx-2 py-4 px-sm-3" >
              <div className="col-12 d-flex logoimg">
                <img src={logo} style={{ width: "16.5rem" }} />
              </div>
              <div className="col-12 d-flex">
                <p className="text"><span style={{ fontSize: "1.8rem", color: "#E46C0A", fontWeight: "bold" }}>"Shaping</span> <span style={{ fontSize: "1.5rem", fontWeight: "bold", color: "#4F81BD" }} >a Cleaner Future Through Precision</span> <span style={{ fontSize: "1.7rem", color: "#E46C0A", fontWeight: "bold" }}>Pollution Monitoring"</span></p>
              </div>
              <div className="col-12">
                {fetchingData && (
                  <div
                    className="alert alert-primary alert-dismissible fade show"
                    role="alert"
                  >
                    {fetchingData}
                  </div>
                )}
              </div>
              <div className="col-12">
                {error && (
                  <div
                    className="alert alert-danger alert-dismissible fade show"
                    role="alert"
                  >
                    {error}
                  </div>
                )}
              </div>
              <div className="col-12" style={{ marginTop: "2rem" }}>
                <input
                  type="text"
                  className="form-control"
                  placeholder="User Id"
                  ref={refUserName}
                  required
                  style={{ padding: "15px" }}
                />
              </div>
              <div className="col-12" style={{ marginTop: "2rem" }}>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  ref={refPassword}
                  required
                  style={{ padding: "15px" }}
                />
              </div>
              <div className="col-12" style={{ marginTop: "2rem" }}>
                <button type="submit" className="btn btn-primary btn-lg" style={{ width: "100%" }}>
                  Login
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;