import React, { useEffect, useState } from "react";
import FormLegend from "../../../components/IndustryDashboard/FormLegend";
import axios from "axios";
import { faL } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";

function CloseDevice() {
  // States
  const [allClosedDevices, setAllClosedDevices] = useState([]);
  const [deviceName, setDeviceName] = useState("");
  const [loading, setLoading] = useState(false);

  // Fetching All Closed Devices Data
  const getAllClosedDevicesData = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${window.apiURL}/close_device/allCloseDevice`
      );
      if (res.status == 200) {
        setAllClosedDevices(res?.data?.allCloseDevice || []);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      if (error.response) {
        Swal.fire(error.response.data.message);
      } else {
        Swal.fire(error.message);
      }
    }
  };

  // Fetchung First Time All Closed Device Data
  useEffect(() => {
    getAllClosedDevicesData();
  }, []);

  // Handle Close Device Button
  const handleCloseDeviceButton = async (e) => {
    e.preventDefault();
    if (!deviceName) {
      Swal.fire("Please Enter Device Name ");
    } else {
      Swal.fire({
        title: "Device Close Confirmation",
        text: "Are you sure you want to close this device?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Close",
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            const res = await axios.post(
              `${window.apiURL}/close_device/addCloseDevice`,
              {
                device_name: deviceName,
              }
            );
            if (res.status == 200) {
              setDeviceName("");
              getAllClosedDevicesData();
              Swal.fire("Device Close Successfully");
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            Swal.fire(error.response.data.message);
          } else {
            Swal.fire(error.message);
          }
        });
    }
  };

  // Handle Remove Device Name From DB Button
  const handleStartDeviceButton = async (e, id) => {
    e.preventDefault();
    Swal.fire({
      title: "Start Device Confirmation",
      text: "Are you sure you want to start this device?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Start",
    })
      .then(async (result) => {
        if (result.isConfirmed) {
          const res = await axios.delete(
            `${window.apiURL}/close_device/removeDeviceFromCloseDevices/${id}`
          );
          if (res.status == 200) {
            getAllClosedDevicesData();
            Swal.fire("Device Start Successfully");
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          Swal.fire(error.response.data.message);
        } else {
          Swal.fire(error.message);
        }
      });
  };

  return (
    <>
      <div className="container-fluid px-3 py-4">
        <div className="row g-3">
          <div className="col-12">
            <div className="data__wrapper p-1 p-sm-3 shadow-sm">
              <div
                className="mx-auto"
                style={{
                  width: "100%",
                }}
              >
                <form style={{ marginBottom: "21px" }}>
                  <fieldset>
                    <FormLegend msg={"Enter Device Name"} />
                    <div className="row g-4 align-items-center mb-2">
                      {/* ================*/}
                      <div className="col-10">
                        <div className="col-10">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Device Name"
                            value={deviceName}
                            onChange={(e) => setDeviceName(e.target.value)}
                          ></input>
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="col-9 col-sm-10 col-xl-11">
                          <div className="d-flex justify-content-end">
                            <button
                              onClick={(e) => handleCloseDeviceButton(e)}
                              className="btn btn-success"
                            >
                              Close Device
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* ================*/}
                    </div>
                  </fieldset>
                </form>

                <form>
                  <fieldset>
                    <FormLegend msg={"Close Devices List"} />
                    {allClosedDevices.length > 0 ? (
                      <div className="row g-3">
                        {/* ================*/}
                        {allClosedDevices?.map((item) => (
                          <div className="row align-items-center mb-2">
                            <div className="col-8">
                              <h5>{item?.device_name}</h5>
                            </div>
                            <div className="col-4">
                              <button
                                onClick={(e) =>
                                  handleStartDeviceButton(e, item?._id)
                                }
                                className="btn btn-danger"
                              >
                                Start Device
                              </button>
                            </div>
                          </div>
                        ))}
                        {/* ================*/}
                      </div>
                    ) : (
                      <div>
                        <h4>No Closed Devices Found Yet!</h4>
                      </div>
                    )}
                  </fieldset>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CloseDevice;
