export const COLUMNS = [
    {
      Header: "Id",
      accessor: "_id",
    },
    {
      Header: "Industry types names",
      accessor: "type_name",
    },
  ];
  