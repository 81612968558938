import React, { memo, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PageHeading, PageWrapper } from "../../../components";
import FormLegend from "../../../components/IndustryDashboard/FormLegend";
import Select from "react-select";
import axios from "axios";
import Swal from "sweetalert2";

const InputGroup = memo(
  ({ index, values, value, handleInputChange, refresh, setRefresh }) => {
    const handleDeleteConstantVariableBtn = (key) => {
      delete values[key];
      setRefresh(!refresh);
    };
    return (
      <div className="input-group mb-3" key={index}>
        <span className="input-group-text" id="basic-addon1">
          V{index + 1}
        </span>
        <input
          type="text"
          className="form-control"
          placeholder="Enter Constant Value"
          aria-label="Username"
          aria-describedby="basic-addon1"
          value={value}
          onChange={(e) => handleInputChange(e.target.value, index)}
        />
        {Object.keys(values).length == index + 1 &&
        Object.keys(values).length != 1 ? (
          <button
            className="btn btn-danger d-flex justify-content-center align-items-center"
            onClick={() => handleDeleteConstantVariableBtn(`V${index + 1}`)}
          >
            <span class="material-symbols-outlined text-white">delete</span>
          </button>
        ) : null}
      </div>
    );
  }
);

const UpdateConstantValue = () => {
  const { device_name } = useParams();
  const [values, setValues] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [ref, setRef] = useState(false);
  useEffect(() => {
    async function fetchAllData() {
      try {
        const res = await axios(
          `${window.apiURL}/constantValueForDisplay/getDisplayConstantValueAccordingToModem/${device_name}`
        );
        setValues(res?.data || {});
      } catch (error) {
        console.log(error.message);
      }
    }
    fetchAllData();
  }, [ref]);
  const handleInputConstantValues = useCallback((value, index) => {
    if (`V${index + 1}` in values) {
      values[`V${index + 1}`] = value;
    } else {
      setValues((prevValues) => ({
        ...prevValues,
        [`V${index + 1}`]: value,
      }));
    }
  }, []);
  const handleAddBtn = () => {
    const len = Object.keys(values).length;
    setValues({
      ...values,
      [`V${len + 1}`]: "",
    });
  };
  const handleSubmitConstantData = async () => {
    Swal.fire({
      title: "Do You Want To Update Constant Variables?",
      text: "are you sure to update constant variable?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Update",
    })
      .then(async (result) => {
        if (result.isConfirmed) {
          const res = await axios.patch(
            `${window.apiURL}/constantValueForDisplay/updateDisplayConstantValueAccordingToModem/${device_name}`,
            {
              data: values,
            }
          );
          if (res.status == 200) {
            setRef(!ref);
            Swal.fire("Constant Values Updated Successfully!");
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          Swal.fire(error.response.data.message);
        } else {
          Swal.fire(error.message);
        }
      });
  };
  return (
    <PageWrapper>
      <div>
        <div className="data__wrapper p-1 p-sm-3 shadow-sm mx-1">
          <form style={{ marginBottom: "21px" }}>
            <fieldset>
              <FormLegend msg={`Update Constant Value Of ${device_name}`} />
              <div className="row align-items-center mb-2">
                {Object.keys(values).map((value, index) => {
                  return (
                    <InputGroup
                      key={index}
                      index={index}
                      values={values}
                      value={values[value] || ""}
                      handleInputChange={handleInputConstantValues}
                      refresh={refresh}
                      setRefresh={setRefresh}
                    />
                  );
                })}
                <div className="mb-2 d-flex justify-content-center align-items-center">
                  <button
                    className="btn btn-primary"
                    onClick={() => handleAddBtn()}
                  >
                    Add
                  </button>
                </div>
                <div className="mb-2 d-grid">
                  <button
                    className="btn btn-success "
                    onClick={handleSubmitConstantData}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </PageWrapper>
  );
};

export default UpdateConstantValue;
