import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import Spinner from "../../components/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocation } from "@fortawesome/free-solid-svg-icons";
import SliderGraph from "./SliderGraph";
import SliderLoggerBox from "./SliderLoggerBox";
import "./SliderUI.css";

// Generating Data From Given Value
const generateDateStr = (d) =>
  `${
    String(d.getDate()).length < 2
      ? "0" + String(d.getDate())
      : String(d.getDate())
  }/${
    String(d.getMonth() + 1).length < 2
      ? "0" + String(d.getMonth() + 1)
      : String(d.getMonth() + 1)
  }/${
    String(d.getFullYear()).length < 2
      ? "0" + String(d.getFullYear())
      : String(d.getFullYear())
  } ${
    String(d.getHours()).length < 2
      ? "0" + String(d.getHours())
      : String(d.getHours())
  }:${
    String(d.getMinutes()).length < 2
      ? "0" + String(d.getMinutes())
      : String(d.getMinutes())
  }:${
    String(d.getSeconds()).length < 2
      ? "0" + String(d.getSeconds())
      : String(d.getSeconds())
  }`;

const SliderUi = () => {
  // Industry Data And Data Values States
  const [industry, setIndustry] = useState([]);
  const [idArr, setIdArr] = useState([]);
  const [dataValue, setDataValue] = useState([]);
  const [currentIDValue, setCurrentIdValue] = useState(
    "63d8afc2b9dacc38e3a9016b"
  );
  const [allDevices, setAllDevices] = useState("M00112+M00111");
  const [indsIdType, setIndsIdType] = useState("all");
  const [isStartButtonVisible, setStartButtonVisible] = useState(false);
  const [isStopButtonVisible, setStopButtonVisible] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);

  // For Device Name And To Change Color of Device Names States
  const [spcbTime, setSpcbTime] = useState("-----------------------");
  const [cpcbTime, setCpcbTime] = useState("-----------------------");
  const [spcbColor, setSpcbColor] = useState("red");
  const [cpcbColor, setCpcbColor] = useState("red");
  const [deviceStates, setDeviceStates] = useState(null);

  // Making Array of IDs of Industry
  async function getIds() {
    const response = await axios(
      `${window.apiURL}/industries/industry/alltype/${indsIdType}`
    );
    const idArray = response.data.IndsIdArray;

    setIdArr(idArray);
  }

  useEffect(() => {
    setCurrentIndex(0);
    getIds();
  }, [indsIdType]);

  const intervalIdRef = useRef(null);

  // Making function so that we can change industery on some interval
  async function loopArray() {
    // console.log(currentIndex);
    if (currentIndex >= 0 && currentIndex < idArr.length) {
      // Fetching Industry Data By Industry ID
      const currentId = idArr[currentIndex];
      const result = await axios(`${window.apiURL}/industries/${currentId}`);
      const industryData = result?.data?.data?.industry;
      setIndustry(industryData);

      // Fetching Data Value of Devices By Device Name
      if (industryData) {
        const device = industryData?.devices
          ?.map((dev) => dev.device_name)
          .join("+");
        setAllDevices(device);
        try {
          const result = await axios(`${window.apiURL}/device_data/${device}`);
          setDataValue(result.data.values);
        } catch (error) {
          console.log(error.response.data.message);
        }
      }

      //+++++++++++++++++++++++++++++++++++++++++++++++

      const deviceState = industryData?.devices?.map(
        ({ status, device_name }) => ({ status, device_name })
      );
      setDeviceStates(deviceState);

      // Code For Print, time of sending data in PCB
      // FOR CPCB
      setCpcbTime("-----------------------");
      (async () => {
        try {
          const result = await axios(
            `${
              window.apiURL
            }/pcb_logs/last_data_by_device?device_name=${deviceState
              .map((item) => item.device_name)
              .join("+")}&board=CPCB`
          );

          if (result.data.log) {
            const currMili = Date.now();
            const d = new Date(result.data.log.createdAt);
            const date = generateDateStr(d);

            if (
              currMili - 1000 * 60 * 60 * 24 * 2 >
              result.data.log.createdAt
            ) {
              setCpcbColor("red");
            } else if (
              currMili - 1000 * 60 * 60 * 4 >
              result.data.log.createdAt
            ) {
              setCpcbColor("yellow");
            } else {
              setCpcbColor("green");
            }

            setCpcbTime(date);
          }
        } catch (error) {
          setCpcbTime("error occur");
        }
      })();

      // FOR SPCB
      setSpcbTime("-----------------------");
      (async () => {
        try {
          const result = await axios(
            `${
              window.apiURL
            }/pcb_logs/last_data_by_device?device_name=${deviceState
              .map((item) => item.device_name)
              .join("+")}&board=SPCB`
          );

          if (result.data.log) {
            const currMili = Date.now();
            const d = new Date(result.data.log.createdAt);
            const date = generateDateStr(d);

            if (
              currMili - 1000 * 60 * 60 * 24 * 2 >
              result.data.log.createdAt
            ) {
              setSpcbColor("red");
            } else if (
              currMili - 1000 * 60 * 60 * 4 >
              result.data.log.createdAt
            ) {
              setSpcbColor("yellow");
            } else {
              setSpcbColor("green");
            }
            setSpcbTime(date);
          }
        } catch (error) {
          setSpcbTime("error occur");
        }
      })();

      // Increasing Current Index
      setCurrentIndex((prev) => prev + 1);
    } else {
      // Restart the array when array length is cover
      setCurrentIndex(0);
      loopArray();
    }
  }
  useEffect(() => {
    if (isStopButtonVisible) {
      intervalIdRef.current = setInterval(loopArray, 5000);
    }

    // Demounting the function on chnage of pages
    return () => {
      clearInterval(intervalIdRef.current);
    };
  }, [idArr, currentIndex, isStopButtonVisible, isStartButtonVisible]);

  // console.log("currentIndex",currentIndex);
  // console.log(deviceStates);
  useEffect(() => {
    // Fetching First Industry Data As Demo
    // console.log(currentIndex);
    (async () => {
      const result = await axios(
        `${window.apiURL}/industries/${currentIDValue}`
      );
      const industryData = result?.data?.data?.industry;
      setIndustry(industryData);

      // Fetching First Device Data As Demo
      if (industryData) {
        try {
          const result = await axios(
            `${window.apiURL}/device_data/M00112+M00111`
          );
          setDataValue(result.data.values);
          const deviceState = industryData?.devices?.map(
            ({ status, device_name }) => ({ status, device_name })
          );
          setDeviceStates(deviceState);
        } catch (error) {
          console.log(error.response.data.message);
        }
      }
    })();

    // Fetching timing of sending data to PCB
    // For PCB
    setCpcbTime("-----------------------");
    (async () => {
      try {
        const result = await axios(
          `${window.apiURL}/pcb_logs/last_data_by_device?device_name=M00112+M00111&board=CPCB`
        );

        if (result.data.log) {
          const currMili = Date.now();
          const d = new Date(result.data.log.createdAt);
          const date = generateDateStr(d);

          if (currMili - 1000 * 60 * 60 * 24 * 2 > result.data.log.createdAt) {
            setCpcbColor("red");
          } else if (
            currMili - 1000 * 60 * 60 * 4 >
            result.data.log.createdAt
          ) {
            setCpcbColor("yellow");
          } else {
            setCpcbColor("green");
          }
          setCpcbTime(date);
        }
      } catch (error) {
        setCpcbTime("error occur");
      }
    })();

    // FOR SPCB
    setSpcbTime("-----------------------");
    (async () => {
      try {
        const result = await axios(
          `${window.apiURL}/pcb_logs/last_data_by_device?device_name=M00112+M00111&board=SPCB`
        );

        if (result.data.log) {
          const currMili = Date.now();
          const d = new Date(result.data.log.createdAt);
          const date = generateDateStr(d);

          if (currMili - 1000 * 60 * 60 * 24 * 2 > result.data.log.createdAt) {
            setSpcbColor("red");
          } else if (
            currMili - 1000 * 60 * 60 * 4 >
            result.data.log.createdAt
          ) {
            setSpcbColor("yellow");
          } else {
            setSpcbColor("green");
          }
          setSpcbTime(date);
        }
      } catch (error) {
        setSpcbTime("error occur");
      }
    })();
  }, []);
  return (
    <div className="container-fluid p-2 overflow-hidden">
      <div className="col-12">
        {/* // Industry Status Selection Bar And Button For Stop and Start Slider  */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "5px",
            marginBottom: "11px",
            border: "1px solid #e2dcdc",
            padding: "4px",
          }}
          className="rounded-3 shadow"
        >
          <select
            className="form-select"
            style={{ fontSize: "15px" }}
            defaultValue={"all"}
            onChange={(e) => setIndsIdType(e.target.value)}
          >
            <option value="all">All</option>
            <option value="online">Online</option>
            <option value="offline">Offline</option>
            <option value="delay">Delay</option>
            <option value="inactive">Inactive</option>
          </select>
          {isStartButtonVisible && (
            <div
              onClick={() => {
                setStopButtonVisible(true);
                setStartButtonVisible(false);
              }}
              style={{ cursor: "pointer" }}
            >
              <span
                style={{
                  fontSize: "20px",
                  padding: "6px",
                  backgroundColor: "red",
                  color: "white",
                }}
                className="material-symbols-outlined shadow-lg rounded-2"
              >
                play_arrow
              </span>
            </div>
          )}
          {isStopButtonVisible && (
            <div
              onClick={() => {
                setStartButtonVisible(true);
                setStopButtonVisible(false);
              }}
              style={{ cursor: "pointer" }}
            >
              <span
                style={{
                  fontSize: "20px",
                  padding: "6px",
                  backgroundColor: "green",
                  color: "white",
                }}
                className="material-symbols-outlined shadow-lg rounded-2"
              >
                pause
              </span>
            </div>
          )}
        </div>
        {/* // Industry Info Box Container  */}
        <div
          className="pt-2 rounded-3 shadow"
          style={{
            position: "-webkit-sticky",
            position: "sticky",
            top: 0,
            backgroundColor: "#f0f5ff",
            zIndex: 10,
            border: "1px solid #cec8c8",
          }}
        >
          {industry ? (
            <div className="col-12">
              <div className="row mediaQueryInHead">
                <div className="col-8">
                  <div style={{ marginLeft: "20px" }}>
                    <h4 className="mb-1">{`${industry.industry_name}`}</h4>
                    <h6>
                      {deviceStates?.map(({ status, device_name }) => {
                        const color =
                          status === "offline"
                            ? "danger"
                            : status === "delay"
                            ? "warning"
                            : status === "online"
                            ? "success"
                            : "muted";
                        return (
                          <span key={device_name} className={`text-${color}`}>
                            {device_name}{" "}
                          </span>
                        );
                      })}
                    </h6>
                    <p>
                      <FontAwesomeIcon icon={faLocation} className="me-1" />
                      <span>
                        {industry.state
                          ? `${industry.state} | ${industry.industry_category} `
                          : "no location "}
                      </span>
                      <span>
                        {industry.ganga_basin === "false"
                          ? "( Not in ganga basin )"
                          : industry.ganga_basin === "true"
                          ? "( Ganga basin )"
                          : "no information about ganga basin"}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="col-4 mediaqueryincpcb d-flex flex-column justify-content-center">
                  <div className="d-flex flex-column justify-content-center align-items-end p-4">
                    <div style={{ width: "max-content" }}>
                      <div
                        className={
                          cpcbColor === "green"
                            ? "light_green_bg rounded-1 py-1 px-2 w-100 mb-2"
                            : cpcbColor === "yellow"
                            ? "yellow_bg rounded-1 py-1 px-2 w-100 mb-2"
                            : "tomato_red_bg rounded-1 py-1 px-2 w-100 mb-2"
                        }
                      >
                        <h6 className="letter_space text-light inline-block m-0">
                          {`CPCB: ${cpcbTime}`}
                        </h6>
                      </div>
                      <div
                        className={
                          spcbColor === "green"
                            ? "light_green_bg rounded-1 py-1 px-2 w-100"
                            : spcbColor === "yellow"
                            ? "yellow_bg rounded-1 py-1 px-2 w-100"
                            : "tomato_red_bg rounded-1 py-1 px-2 w-100"
                        }
                      >
                        <h6 className="letter_space text-light inline-block m-0">
                          {`SPCB: ${spcbTime}`}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        {/* // Data Values Box And Map Container  */}
        <div
          style={{ display: "flex", gap: "10px", marginTop: "15px" }}
          className="addMediaQuery"
        >
          {/* // First Div For Show Live Data Values Box */}
          <div
            className="mediaQueryForLoggerBox rounded-3 shadow-lg"
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
              paddingTop: "1rem",
              paddingBottom: "1rem",
              overflow: "auto",
              border: "1px solid #cec8c8",
              backgroundColor: "white",
            }}
          >
            {dataValue ? (
              dataValue?.map((item, i) => {
                return <SliderLoggerBox key={i} data={item} />;
              })
            ) : (
              <>
                <div className="col-3">
                  <div className="box_bg rounded-1 py-2 shadow">
                    <div className="p-4">
                      <Spinner />
                    </div>
                  </div>
                </div>
                <div className="col-3">
                  <div className="box_bg rounded-1 py-2 shadow">
                    <div className="p-4">
                      <Spinner />
                    </div>
                  </div>
                </div>
                <div className="col-3">
                  <div className="box_bg rounded-1 py-2 shadow">
                    <div className="p-4">
                      <Spinner />
                    </div>
                  </div>
                </div>
                <div className="col-3">
                  <div className="box_bg rounded-1 py-2 shadow">
                    <div className="p-4">
                      <Spinner />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          {/* // Second div for show Graph Of Live Data Values  */}
          <div
            className="bg-white shadow-lg  rounded-3 mediaQueryForGraphBox"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: "1px solid #cec8c8",
            }}
          >
            <SliderGraph devices={allDevices} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SliderUi;
