import axios from "axios";
import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";

const Subscription = () => {
  const [industry, setIndustry] = useState("");
  const [station, setStation] = useState("");
  const [subscription, setSubscription] = useState("");
  const [industryName, setIndustryName] = useState([]);
  const [stationName, setStationName] = useState([]);
  const [data, setData] = useState([]);

  // Pagination Logic
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(50); // Set the number of items per page
  const [displayData, setDisplayData] = useState([]);

  const handlePageClick = (event) => {
    setPage(event.selected + 1);
  };

  useEffect(() => {
    // Calculate the start and end indices for the current page
    const start = (page - 1) * itemsPerPage;
    const end = start + itemsPerPage;

    // Update the displayData based on the current page
    setDisplayData(data?.slice(start, end));
  }, [page, data, itemsPerPage, industry, station, subscription]);

  const getIndustryAndStationName = async () => {
    try {
      const result = await axios(`${window.apiURL}/industry_names`);
      setIndustryName(result?.data?.data?.industry_names);
    } catch (error) {
      console.log(error);
    }
  };

  const getIndustryRenewalData = async () => {
    try {
      const result = await axios(
        `${window.apiURL}/users/renew/getAllUsersParameterDetail`
      );
      setData(result?.data?.details);
      setTotalPage(result?.data?.length);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getIndustryRenewalData();
  }, []);

  const getStationNameOfParticularIndustry = async () => {
    try {
      const result = await axios(
        `${window.apiURL}/users/renew/getAllUsersParameterDetail/${
          industry || "empty"
        }/${station || "empty"}/${subscription || "empty"}`
      );
      console.log(result?.data?.details);
      setData(result?.data?.details);
      setTotalPage(result?.data?.length);
      if (result?.data?.uniqueStationNamesArray) {
        setStationName(result?.data?.uniqueStationNamesArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (industry || station || subscription) {
      getStationNameOfParticularIndustry();
    }
  }, [industry, station, subscription]);

  useEffect(() => {
    getIndustryAndStationName();
  }, []);

  return (
    <div className="p-2">
      {/* // Filteration Menu  */}
      <div
        style={{
          display: "flex",
          gap: "5px",
          alignItems: "center",
          marginLeft: "1px",
          border: "1px solid #cec8c8",
          padding: "4px",
        }}
        className="rounded-3 shadow flex-column flex-md-row"
      >
        <div className="w-100">
          <select
            className="form-select w-100 "
            // aria-label="Default select example"
            onChange={(e) => setIndustry(e.target.value)}
          >
            <option value={"empty"} selected>
              Select Industry Name
            </option>
            {/* {console.log(industryName)} */}
            {industryName.length &&
              industryName?.map((item) => (
                <option value={item?._id}>{item?.industry_name}</option>
              ))}
          </select>
        </div>
        <div className="w-100">
          <select
            className="form-select w-100 "
            // aria-label="Default select example"
            onChange={(e) => setStation(e.target.value)}
          >
            <option value={"empty"} selected>
              Select Station Name
            </option>
            {stationName?.map((item) => (
              <option value={item}>{item}</option>
            ))}
          </select>
        </div>
        <div className="w-100">
          <select
            className="form-select w-100 "
            // aria-label="Default select example"
            onChange={(e) => setSubscription(e.target.value)}
          >
            <option value={"empty"} selected>
              Select Subscription Status
            </option>
            <option value={"true"}>Subscribed</option>
            <option value={"false"}>Not Subscribed</option>
            <option value={"less20"}>Less Than 20 Days</option>
          </select>
        </div>
        <div
          className="bg-white rounded-2 px-2"
          style={{ border: "1px solid #e2dcdc" }}
        >
          <h1
            style={{
              fontSize: "17px",
              color: "blue",
              letterSpacing: 1,
              marginTop: "6px",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            {data?.length}
          </h1>
        </div>
      </div>

      {/* // Data Table  */}
      <div
        style={{
          padding: "5px",
          backgroundColor: "white",
          marginTop: "11px",
          border: "1px solid #cec8c8",
          padding: "6px",
        }}
        className="table-responsive rounded-3 shadow"
      >
        <table className="table table-bordered table-striped">
          <thead
            className="table-light align-middle bg-[#808080]"
            style={{ backgroundColor: "#808080" }}
          >
            <tr>
              <th
                scope="col"
                style={{ backgroundColor: "#808080", color: "white" }}
              >
                Industry Name
              </th>
              <th
                scope="col"
                style={{ backgroundColor: "#808080", color: "white" }}
              >
                UserName
              </th>
              <th
                scope="col"
                style={{ backgroundColor: "#808080", color: "white" }}
              >
                Parameter Name
              </th>
              <th
                scope="col"
                style={{ backgroundColor: "#808080", color: "white" }}
              >
                Station Name
              </th>
              <th
                scope="col"
                style={{ backgroundColor: "#808080", color: "white" }}
              >
                Subscribed_Date
              </th>
              <th
                scope="col"
                style={{ backgroundColor: "#808080", color: "white" }}
              >
                Renewal_Date
              </th>
              <th
                scope="col"
                style={{ backgroundColor: "#808080", color: "white" }}
              >
                Days Left
              </th>
              <th
                scope="col"
                style={{ backgroundColor: "#808080", color: "white" }}
              >
                Subscription Period
              </th>

              <th
                scope="col"
                style={{ backgroundColor: "#808080", color: "white" }}
              >
                Subscription Status
              </th>
            </tr>
          </thead>
          <tbody className="align-middle">
            {displayData?.length > 0 ? (
              displayData?.map((item) => (
                <tr>
                  <td>{item?.industry}</td>
                  <td>{item?.username}</td>
                  <td>{item?.parameters}</td>
                  <td>{item?.station_name}</td>
                  <td>{item?.from}</td>
                  <td>{item?.lastDateOfSubscribtion}</td>
                  <td
                    style={{
                      color:
                        item?.daysLeft > 20
                          ? "green"
                          : item?.daysLeft > 1
                          ? "orange"
                          : "red",
                      fontWeight: "bolder",
                    }}
                  >
                    {item?.daysLeft}
                  </td>
                  <td>{item?.till} Days</td>
                  <td
                    style={{
                      color: "white",
                      fontWeight: "bolder",
                      backgroundColor:
                        item?.daysLeft > 20
                          ? "green"
                          : item?.daysLeft > 1
                          ? "orange"
                          : "red",
                      textAlign: "center",
                    }}
                  >
                    {item?.status == true ? "Subscribed" : "Not Subscribed"}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td>--------</td>
                <td>--------</td>
                <td>--------</td>
                <td>--------</td>
                <td>--------</td>
                <td>--------</td>
                <td>--------</td>
                <td>--------</td>
                <td>--------</td>
              </tr>
            )}
          </tbody>
        </table>

        {/* // Paginaton  */}
        <div className="col-12">
          <div className="d-flex justify-content-center">
            <ReactPaginate
              breakLabel="..."
              nextLabel="next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={8}
              pageCount={Math.ceil(totalPage / itemsPerPage)}
              previousLabel="< previous"
              renderOnZeroPageCount={null}
              marginPagesDisplayed={4}
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscription;
