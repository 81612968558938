import axios from "axios";
import React, { useRef, useState, useEffect } from "react";
import FormLegend from "../../../components/IndustryDashboard/FormLegend";

const CreateNewGeneratedData = () => {
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const refDeviceName = useRef(null);
  const refState = useRef(null);
  const refValue = useRef(null);

  useEffect(() => {
    const timeId = setTimeout(() => {
      if (message || error) {
        setMessage("");
        setError("");
      }
    }, 3000);
    return () => {
      clearTimeout(timeId);
    };
  }, [message, error]);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const dataObj = {
        device_name: refDeviceName.current.value.trim(),
        state: refState.current.value.trim(),
        values: refValue.current.value.trim(),
      };
      const res = await axios.post(
        `${window.apiURL}/send_gen_data/add`,
        dataObj
      );
      if (res.status === 201) {
        setMessage("Details added successfully!");
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  return (
    <>
      <div className="container-fluid px-3 py-4">
        <div className="row g-3">
          <div className="col-12">
            <div className="data__wrapper p-1 p-sm-3 shadow-sm">
              <div
                className="mx-auto"
                style={{
                  width: "100%",
                  overflowX: "scroll",
                }}
              >
                <form onSubmit={handleSubmit}>
                  <fieldset>
                    <FormLegend msg={"Create New Generated Data"} />
                    <div className="row g-3">
                      {/* ============================================= */}
                      <div className="col-9 col-sm-10 col-xl-11">
                        <input
                          ref={refDeviceName}
                          required
                          type="text"
                          className="form-control"
                          placeholder="Device name *"
                        ></input>
                      </div>
                      <div className="col-9 col-sm-10 col-xl-11">
                        <select
                          ref={refState}
                          defaultValue={"start"}
                          className="form-select"
                        >
                          <option value="start">start</option>
                          <option value="stop">stop</option>
                        </select>
                      </div>
                      <div className="col-9 col-sm-10 col-xl-11">
                        <input
                          ref={refValue}
                          type="text"
                          className="form-control"
                          placeholder="Example: 20,21|10,11"
                        ></input>
                      </div>
                      {/* ============================================= */}
                      {/* col-10  */}
                      {/* <div className="col-9 col-sm-10 col-xl-11">
                        <select
                          defaultValue={"DEFAULT_Industry"}
                          className="form-select"
                        >
                          <option disabled value="DEFAULT_Industry">
                            about
                          </option>
                          <option value="team leader">team leader</option>
                          <option value="owner">owner</option>
                          <option value="office boy">office boy</option>
                        </select> 
                      </div> */}

                      {/* col-10  */}

                      {message && (
                        <div className="col-9 col-sm-10 col-xl-11">
                          <div
                            className="alert alert-success px-2"
                            role="alert"
                          >
                            {message}
                          </div>
                        </div>
                      )}
                      {error && (
                        <div className="col-9 col-sm-10 col-xl-11">
                          <div className="alert alert-danger" role="alert">
                            {error}
                          </div>
                        </div>
                      )}
                      <div className="col-9 col-sm-10 col-xl-11">
                        <div className="d-flex justify-content-end py-2">
                          <button className="btn btn-secondary me-3">
                            Cancel
                          </button>
                          <button type="submit" className="btn btn-success">
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateNewGeneratedData;
