import React from "react";

const Stations = () => {
  return (
    <div>
      <h1>Stations</h1>
    </div>
  );
};

export default Stations;
