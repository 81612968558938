export const COLUMNS = [
  {
    Header: "Id",
    accessor: "_id",
  },
  {
    Header: "Device name",
    accessor: "device_name",
  },
  {
    Header: "Status",
    accessor: "state",
  },
  {
    Header: "Values",
    accessor: "values",
  },
];
