import React from "react";

const ChangePassword = () => {
  return (
    <div>
      <h1>ChangePassword</h1>
    </div>
  );
};

export default ChangePassword;
