import React from "react";
import ReactDOM from "react-dom/client";
import { HashRouter } from "react-router-dom";
import mant from "../src/images/Maintenance.png";

// bootstarp
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";

// common scss
import App from "./App";
import { AppProvider } from "./context";
import "./index.scss";
// setInterval(() => {
//   window.location.reload();
// }, 1000 * 30);
window.apiURL = "https://api.accesssurveykshan.co.in/api/v1";
window.graphApiURL = "https://graph-data.accesssurveykshan.co.in/api/v1";
// window.graphApiURL = "http://localhost:9000/api/v1";
// window.apiURL = "http://localhost:8080/api/v1";
// git commit -m
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // // Site Under Maintance Theme Setup
  // <div
  //   style={{
  //     height: "100vh",
  //     width: "100vw",
  //     display: "flex",
  //     justifyContent: "center",
  //     alignItems: "center",
  //   }}
  // >
  //   <img src={mant} />
  // </div>
  <AppProvider>
    <HashRouter>
      <App />
    </HashRouter>
  </AppProvider>
);
