export const COLUMNS = [
    {
      Header: "Id",
      accessor: "_id",
    },
    {
      Header: "Industry partner names",
      accessor: "partner_name",
    },
  ];
  