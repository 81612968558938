import React from "react";
const Licenses = () => {
  return (
    <div>
      <h1>Licenses</h1>
    </div>
  );
};

export default Licenses;
