import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import "./SliderUI.css"


Chart.register(...registerables);
Chart.register(zoomPlugin);

// Creating Structure of Graph
const LineChart1 = (({ chartData }) => {
    const myRef = useRef(null);
    return (
        <div className="chart-container">
            <h4 className="my-3 position-relative" style={{ textAlign: "center" }}>
                Parameters Chart{" "}
                <button
                    className="btn btn-sm btn-outline-dark position-absolute top-0 end-0 fw-bold"
                    onClick={() => myRef.current.resetZoom()}
                >
                    Reset Zoom
                </button>
            </h4>
            <Line
                ref={myRef}
                data={chartData}
                options={{
                    plugins: {
                        title: {
                            display: false,
                        },
                        legend: {
                            display: true,
                        },
                        pan: {
                            enabled: true,
                        },
                        zoom: {
                            zoom: {
                                wheel: {
                                    enabled: true,
                                    drag: true,
                                },

                                pinch: {
                                    enabled: true,
                                    drag: true,
                                },
                                mode: "x",
                            },
                            pan: {
                                enabled: true,
                                mode: "x",
                            },
                        },
                    },
                }}
            />
        </div>
    );
});

// Adding Extra Configuration For Graph
const getDataSet = (arr) => {
    const colorArr = [
        "#FF0000",
        "#00FF00",
        "#0000FF",
        "#FF00FF",
        "#FFFF00",
        "#00FFFF",
        "#FFA500",
        "#800080",
        "#008000",
        "#800000",
        "#008080",
        "#FF4500",
        "#9400D3",
        "#00FF7F",
        "#FF1493",
        "#00BFFF",
        "#8A2BE2",
        "#32CD32",
        "#DC143C",
        "#FF69B4",
    ];
    let hold = {};
    let result = [];
    for (let i = 0; i < arr?.length; i++) {
        const data = arr[i];
        for (const key in data) {
            if (!hold[key]) {
                hold[key] = true;
            } else if (key !== "createdAt") {
            }
            hold[key] = true;
        }
    }

    for (const key in hold) {
        if (key !== "createdAt") {
            result.push({
                label: key,
                data: arr?.map((data) => data[key]),
                backgroundColor: ["#ecf0f1"],
                borderColor: colorArr.pop() || "#000000",
                borderWidth: 1,
                pointRadius: 5,
            });
        }
    }
    return result;
};

const SliderGraph = ({ devices }) => {
    // Creating States For Graph
    const [chartData, setChartData] = useState(null);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [allData, setAllData] = useState([]);
    const [dataAvg, setDataAvg] = useState(null);
    const [intervalTime, setIntervalTime] = useState(15);
    const [tableHeaderValue, setHeaderValue] = useState([]);

    // Creating Present Time Instance and Fetching time, date etc seperately
    const today = new Date();
    let todayDate =
        `${today.getDate()}`.length === 1 ? `0${today.getDate()}` : today.getDate();

    let todayMonth =
        `${today.getMonth() + 1}`.length === 1
            ? `0${today.getMonth() + 1}`
            : today.getMonth() + 1;

    let todayYear = today.getFullYear()

    // Adding Data in Chart
    useEffect(() => {
        // if (allData.length > 0) {
        const labels = allData?.map((data) => data.createdAt);
        const datasets = getDataSet(allData);
        setChartData({ labels, datasets });
        // }
    }, [allData])

    // Set Current Time
    useEffect(() => {
        setStartDate(`${todayYear}-${todayMonth}-${todayDate}`);
        setEndDate(`${todayYear}-${todayMonth}-${todayDate}`);
        setStartTime(`00:00:00`);
        setEndTime(`${String(today.getHours()).length === 1
            ? `0${today.getHours()}`
            : today.getHours()
            }:${String(today.getMinutes()).length === 1
                ? `0${today.getMinutes()}`
                : today.getMinutes()
            }:${String(today.getSeconds()).length === 1
                ? `0${today.getSeconds()}`
                : today.getSeconds()
            }`)
    }, [])


    // Fetching All Graph Data By API and Set in States
    const getData = async (devices, intervalTime, startDate, endDate, startTime, endTime) => {

        if (devices && intervalTime && startDate && endDate && startTime && endTime) {
            try {
                setAllData([]);
                const res = await axios.get(`${window.graphApiURL}/surveykshan-get-data-api/rawdata/${devices}/${intervalTime}/${startDate}/${endDate}/${startTime}/${endTime}`)
                const tableHeadArr = Object.entries(res.data.head).filter((item) => {
                    if (item !== "createdAt") {
                        return item;
                    }
                })
                tableHeadArr.unshift("createdAt")
                setHeaderValue(tableHeadArr);
                setAllData(res.data.data);
                setDataAvg(res.data.avgData);
            } catch (error) {
                console.log(error.message);
            }
        }
    }
    // Calling Function Also On State Change
    useEffect(() => {
        getData(devices, intervalTime, startDate, endDate, startTime, endTime)
    }, [devices, intervalTime, startDate, endDate, startTime, endTime]);


    return (
        <div className="container-fluid p-3">
            <div className="col-12">
                <div className="p-2 p-sm-3 bg-white shadow mt-2">
                    {chartData && <LineChart1 chartData={chartData} />}
                </div>
            </div>
        </div>
    );
};
export default SliderGraph;