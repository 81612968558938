import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { nanoid } from "nanoid";
import noCameraImg from "../../../images/no-camera.png";
import Spinner from "../../Spinner";
import ReactPlayer from "react-player";
import jsmpeg from "jsmpeg";

const Camera = () => {
  const canvasRef = useRef();
  const { industry_name } = useParams();

  const [message, setMessage] = useState("");

  const [allData, setAllData] = useState("");
  const [allIps, setAllIps] = useState("");
  const [selectedIp, setSelectedIp] = useState("");
  const [selectedIpData, setSelectedIpData] = useState("");
  const url = selectedIpData ? selectedIpData.live_stream_url : "";

  useEffect(() => {
    (async () => {
      try {
        const result = await axios(`${window.apiURL}/camera/${industry_name}`);
        const data = result.data.data.cameras;
        const ips = data.map(
          ({ camera_ip, live_stream_port }) =>
            `${camera_ip}:${live_stream_port}`
        );
        setAllData(data);
        setAllIps(ips);
        setSelectedIp(ips[0]);
        setSelectedIpData(data[0]);
      } catch (error) {
        setMessage(error.message);
      }
    })();
  }, []);

  useEffect(() => {
    try {
      if (selectedIpData) {
        // console.log(selectedIpData)
        const canvas = canvasRef.current;
        const websocket = new WebSocket(
          `wss://abhicamera.accesssurveykshan.co.in/${selectedIpData?.connectivity_type}/`
        );
        // ${selectedIpData.live_stream_port}
        websocket.onopen = () => {
          // console.log("WebSocket connection established");
        };

        websocket.onerror = (error) => {
          // console.error("WebSocket error:", error);
        };

        websocket.onclose = (event) => {
          if (event.wasClean) {
            // console.log(WebSocket connection closed cleanly, code=${event.code}, reason=${event.reason});
          } else {
            // console.error("WebSocket connection closed abruptly");
          }
        };

        const player = new jsmpeg(websocket, {
          canvas: canvas,
          autoplay: true,
          loop: true,
        });

        return () => {
          websocket.close();
        };
      }
    } catch (error) {
      // console.log("eror", error);
    }
  }, [selectedIpData]);

  return (
    <div className="container-fluid py-3">
      {message ? (
        <div className="py-4">
          <h6 className="text-center">{message}</h6>
        </div>
      ) : !allIps ? (
        <div className="col-12">
          <div className="box_bg rounded-1 py-4 mt-3">
            <div className="p-4">
              <Spinner />
            </div>
          </div>
        </div>
      ) : allIps.length < 1 ? (
        <div className="box_bg rounded-1 p-3">
          <h5 className="text-center py-3">No camera on this site.</h5>
          <div className="d-flex align-items-center justify-content-center">
            <img style={{ width: "200px" }} src={noCameraImg} alt="" />
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-md-8">
            <div style={{ width: "100%" }}>
              {/* <iframe
              style={{ width: "100%", height: "350px",overflow:"auto" }}
              src={"https://camera.accesssurveykshan.co.in/api/v1/camera/get_camera/8000"}
            ></iframe> */}
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ backgroundColor: "black" }}
              >
                {/* <ReactPlayer
                  url={url}
                  playing={true}
                  width="100%"
                  height="100%"
                /> */}
                <canvas
                  style={{ width: "100%", height: "100%" }}
                  ref={canvasRef}
                  id="video-canvas"
                ></canvas>
                {/* <video width="352" height="288" src="http://127.0.0.1:4000/index.m3u8" controls autoPlay={true}>
            </video> */}
              </div>
            </div>

            {/* <canvas
              id="chanel1"
              style={{ border: "2px solid black", width: "100%" }}
            ></canvas> */}
          </div>
          <div className="col-md-4 p-0">
            <div className="row ">
              <div className="col-12">
                {selectedIp && allIps ? (
                  <div className="dropdown">
                    <button
                      className="btn btn-secondary dropdown-toggle w-100"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {selectedIp}
                    </button>
                    <ul
                      className="dropdown-menu w-100"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      {allIps.map((ip) => (
                        <li key={nanoid()} style={{ cursor: "pointer" }}>
                          <span
                            onClick={() => {
                              setSelectedIp(ip);
                              setSelectedIpData(
                                allData.filter(
                                  (item) =>
                                    `${item.camera_ip}:${item.live_stream_port}` ===
                                    ip
                                )[0]
                              );
                            }}
                            className="dropdown-item"
                          >
                            {ip}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <button className="btn btn-secondary" type="button" disabled>
                    Loading...
                  </button>
                )}
              </div>
              <div className="col-12 mt-3">
                {selectedIpData ? (
                  <div className="card ">
                    <div className="card-header p-2">
                      <h6 className="mb-1">Camera Details</h6>
                    </div>
                    <div className="row ">
                      <div className="col-6 ">
                        <p className="px-2 m-0">Camera Make</p>
                      </div>
                      <div className="col-6">
                        <p className="px-2 m-0">
                          {selectedIpData.camera_company}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <p className="px-2 m-0">Camera Model No.</p>
                      </div>
                      <div className="col-6">
                        <p className="px-2 m-0">
                          {selectedIpData.model_number}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <p className="px-2 m-0">PTZ</p>
                      </div>
                      <div className="col-6">
                        <p className="px-2 m-0">
                          {selectedIpData.ptz ? "Yes" : "No"}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <p className="px-2 m-0">10x Zoom</p>
                      </div>
                      <div className="col-6">
                        <p className="px-2 m-0">
                          {selectedIpData.zoom ? "Yes" : "No"}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <p className="px-2 m-0">Night Vision</p>
                      </div>
                      <div className="col-6">
                        <p className="px-2 m-0">
                          {" "}
                          {selectedIpData.night_vision ? "Yes" : "No"}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <p className="px-2 m-0">IP Camera</p>
                      </div>
                      <div className="col-6">
                        <p className="px-2 m-0">
                          {selectedIpData.ip_camera ? "Yes" : "No"}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <p className="px-2 m-0">Connectivity Type</p>
                      </div>
                      <div className="col-6">
                        <p className="px-2 m-0">
                          {selectedIpData.connectivity_type}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <p className="px-2 m-0">Bandwidth</p>
                      </div>
                      <div className="col-6">
                        <p className="px-2 m-0">
                          {selectedIpData.bandwidth_available}
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Camera;
