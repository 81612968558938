import React, { useEffect, useState, memo, useCallback } from "react";
import { PageHeading, PageWrapper } from "../../../components";
import FormLegend from "../../../components/IndustryDashboard/FormLegend";
import axios from "axios";
import Select from "react-select";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const InputGroup = memo(
  ({ index, values, value, handleInputChange, refresh, setRefresh }) => {
    const handleDeleteConstantVariableBtn = (key) => {
      delete values[key];
      setRefresh(!refresh);
    };
    return (
      <div className="input-group mb-3" key={index}>
        <span className="input-group-text" id="basic-addon1">
          V{index + 1}
        </span>
        <input
          type="text"
          className="form-control"
          placeholder="Enter Constant Value"
          aria-label="Username"
          aria-describedby="basic-addon1"
          value={value}
          onChange={(e) => handleInputChange(e.target.value, index)}
        />
        {Object.keys(values).length == index + 1 ? (
          <button
            className="btn btn-danger d-flex justify-content-center align-items-center"
            onClick={() => handleDeleteConstantVariableBtn(`V${index + 1}`)}
          >
            <span class="material-symbols-outlined text-white">delete</span>
          </button>
        ) : null}
      </div>
    );
  }
);

const ConstantValue = () => {
  const [deviceNames, setDeviceNames] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState("");
  const [values, setValues] = useState({});
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(false);
  const [ref, setRef] = useState(false);

  const handleInputConstantValues = useCallback((value, index) => {
    if (`V${index + 1}` in values) {
      values[`V${index + 1}`] = value;
    } else {
      setValues((prevValues) => ({
        ...prevValues,
        [`V${index + 1}`]: value,
      }));
    }
  }, []);
  useEffect(() => {
    async function fetchAllDevices() {
      try {
        const res = await axios(`${window.apiURL}/device/get_all_devices_data`);
        setDeviceNames(res?.data?.data?.devices || []);
      } catch (error) {
        console.log(error.message);
      }
    }
    fetchAllDevices();
  }, []);
  useEffect(() => {
    async function fetchAllData() {
      setLoading(true);
      try {
        const res = await axios(
          `${window.apiURL}/constantValueForDisplay/getDisplayConstantValueOfAllModem`
        );
        setLoading(false);
        setData(res?.data?.data || []);
      } catch (error) {
        setLoading(false);
        console.log(error.message);
      }
    }
    fetchAllData();
  }, [ref]);
  const handleAddBtn = () => {
    const len = Object.keys(values).length;
    setValues({
      ...values,
      [`V${len + 1}`]: "",
    });
  };
  const handleSubmitConstantData = async () => {
    Swal.fire({
      title: "Do You Want To Create New Constant Variable For Display?",
      text: "Are you sure for create new constant variable?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Create",
    })
      .then(async (result) => {
        if (result.isConfirmed) {
          const res = await axios.post(
            `${window.apiURL}/constantValueForDisplay/addDisplayConstantValueOfModem`,
            {
              device_name: selectedDevice,
              data: values,
            }
          );
          if (res.status == 200) {
            setValues({});
            setSelectedDevice("");
            setRef(!ref);
            Swal.fire("New Constant Value Created Successfully!");
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          Swal.fire(error.response.data.message);
        } else {
          Swal.fire(error.message);
        }
      });
  };
  const handleConstantVariableDeleteBtn = async (device_name) => {
    Swal.fire({
      title: "Do You Want Delete Constant Variable?",
      text: "Are you sure for delete constant variable?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Delete",
    })
      .then(async (result) => {
        if (result.isConfirmed) {
          const res = await axios.delete(
            `${window.apiURL}/constantValueForDisplay/deleteDisplayConstantValueAccordingToModem/${device_name}`
          );
          if (res.status == 200) {
            setRef(!ref);
            Swal.fire("Constant Value Deleted Successfully!");
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          Swal.fire(error.response.data.message);
        } else {
          Swal.fire(error.message);
        }
      });
  };

  return (
    <PageWrapper>
      <div className="col-12">
        <div className="d-flex justify-content-between">
          <div>
            <PageHeading txt={"Constant Values"} />
          </div>
        </div>
      </div>
      <div>
        <div className="data__wrapper p-1 p-sm-3 shadow-sm mx-1">
          <form style={{ marginBottom: "21px" }}>
            <fieldset>
              <FormLegend msg={"Create Constant Value For Display"} />
              <div className="row g-4 align-items-center mb-2">
                <div className="col-12">
                  <div className="col-12 mb-2">
                    <Select
                      className=""
                      placeholder="Select Device"
                      options={deviceNames?.map((item) => {
                        return {
                          value: item?.device_name,
                          label: item?.device_name,
                        };
                      })}
                      onChange={(e) => {
                        setSelectedDevice(e?.value);
                      }}
                    />
                  </div>
                  {selectedDevice !== "" ? (
                    <>
                      <div className="mb-2 d-flex justify-content-center align-items-center">
                        <button
                          className="btn btn-primary"
                          onClick={() => handleAddBtn()}
                        >
                          Add
                        </button>
                      </div>
                      {Object.keys(values).map((value, index) => {
                        return (
                          <InputGroup
                            key={index}
                            index={index}
                            values={values}
                            value={values[value] || ""}
                            handleInputChange={handleInputConstantValues}
                            refresh={refresh}
                            setRefresh={setRefresh}
                          />
                        );
                      })}
                    </>
                  ) : (
                    <h6 className="text-center text-danger">
                      First Select Device!
                    </h6>
                  )}
                  {selectedDevice !== "" && Object.keys(values).length > 0 ? (
                    <div className="mb-2 d-grid" onClick={() => handleAddBtn()}>
                      <button
                        className="btn btn-success "
                        onClick={handleSubmitConstantData}
                      >
                        Submit
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
      <div>
        <div className="data__wrapper p-1 p-sm-3 shadow-sm mx-1">
          <form style={{ marginBottom: "21px" }}>
            <fieldset>
              <FormLegend msg={"Created Constant Values List"} />
              <div>
                {loading ? (
                  <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <div
                    style={{
                      width: "100%",
                      overflow: "auto",
                    }}
                  >
                    <table className="table table-striped table-hover">
                      <thead className="table-primary text-center align-middle">
                        <tr>
                          <th scope="col">S. No.</th>
                          <th scope="col">Device Name</th>
                          <th scope="col">Constant Values</th>
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>
                      <tbody className="text-center align-middle">
                        {data?.length > 0 ? (
                          data?.map((item, index) => (
                            <tr key={item?._id}>
                              <th scope="row">{index + 1}</th>
                              <td>{item?.device_name}</td>
                              <td>
                                {Object.keys(item?.data).map((key) => {
                                  return (
                                    <div>
                                      <p>
                                        {key}&nbsp;&nbsp;:&nbsp;&nbsp;
                                        <span style={{ fontWeight: "bold" }}>
                                          {item?.data[key]}
                                        </span>
                                      </p>
                                    </div>
                                  );
                                })}
                              </td>
                              <td>
                                <div className="mb-2 d-flex justify-content-center align-items-center gap-4">
                                  <button
                                    className="btn btn-primary d-flex justify-content-center align-items-center"
                                    onClick={() =>
                                      navigate(
                                        `/updateConstantValue/${item?.device_name}`
                                      )
                                    }
                                  >
                                    <span className="material-symbols-outlined text-white">
                                      edit_square
                                    </span>
                                  </button>
                                  <button
                                    className="btn btn-danger d-flex justify-content-center align-items-center"
                                    onClick={() =>
                                      handleConstantVariableDeleteBtn(
                                        item?.device_name
                                      )
                                    }
                                  >
                                    <span class="material-symbols-outlined text-white">
                                      delete
                                    </span>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <h6 className="text-center text-danger">
                            Constant Variable Data Not Created Yet!
                          </h6>
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </PageWrapper>
  );
};

export default ConstantValue;
