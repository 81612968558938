import React from "react";

const CreateNewConsumable = () => {
  return (
    <div>
      <h1>CreateNewConsumable</h1>
    </div>
  );
};

export default CreateNewConsumable;
