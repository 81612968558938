import React, { useRef } from "react";
import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";

Chart.register(...registerables);
Chart.register(zoomPlugin);

const LineChart = React.forwardRef(({ chartData }, ref) => {
  const myRef = useRef(null);

  return (
    <div ref={ref} className="chart-container">
      <h4 className="my-3 position-relative" style={{ textAlign: "center" }}>
        Parameters Chart{" "}
        <button
          className="btn btn-sm btn-outline-dark position-absolute top-0 end-0 fw-bold"
          onClick={() => myRef.current.resetZoom()}
        >
          Reset Zoom
        </button>
      </h4>
      <Line
        ref={myRef}
        data={chartData}
        options={{
          plugins: {
            title: {
              display: false,
            },
            legend: {
              display: true,
            },
            pan: {
              enabled: true,
            },
            zoom: {
              zoom: {
                wheel: {
                  enabled: true,
                  drag: true,
                },

                pinch: {
                  enabled: true,
                  drag: true,
                },
                mode: "x",
              },
              pan: {
                enabled: true,
                mode: "x",
              },
            },
          },
        }}
      />
    </div>
  );
});
export default LineChart;
