export const COLUMNS = [
    {
      Header: "Id",
      accessor: "_id",
    },
    {
      Header: "Industry category names",
      accessor: "category_name",
    },
  ];
  